<template>
  <div class="form-layout">
    <div class="content-header cols flex-cell--static">
      <div class="back-button">
        <slot name="back-button"></slot>
      </div>
      <h2>
        <slot name="title"></slot>
      </h2>
      <div class="left-buttons">
        <slot name="left-buttons"></slot>
      </div>
      <div class="tabs">
        <slot name="tabs"></slot>
      </div>
      <div class="right-buttons">
        <slot name="right-buttons"></slot>
      </div>
    </div>

    <div class="content-body">
      <div class="flex-cell" v-if="horizontalCenter"></div>

      <div class="rows left-sidebar">
        <div class="left-tabs">
          <slot name="left-tabs"></slot>
        </div>
        <div class="left-controls">
          <slot name="left-controls"></slot>
        </div>
      </div>

      <div class="rows">
        <div class="flex-cell" v-if="verticalCenter"></div>

        <slot name="body">
          <div>...</div>
        </slot>

        <div class="flex-cell" v-if="verticalCenter"></div>
      </div>

      <div class="flex-cell" v-if="horizontalCenter"></div>
    </div>
  </div>
</template>

<script>
import Component from 'vue-class-component';

@Component({
  name: 'form-layout',
  props: {
    alignBody: {
      type: String,
      default: 'left'
    }
  }
})
export default class FormLayout extends Component {
  get horizontalCenter() {
    return this.alignBody === 'center';
  }

  get verticalCenter() {
    return this.alignBody === 'center';
  }
}
</script>

<style>
.left-sidebar {
  overflow: hidden;
  flex-shrink: 0;
  margin-right: 2rem;
  width: 13rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
