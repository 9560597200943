<template>
  <div v-if="!isCreateMode" v-loading="loading">
    <template v-if="hasDetector">
      <common-tabs v-model="objects" :items="tabs" class="header-tabs"></common-tabs>
      <br />
      <div v-if="image">
        <p class="parameter-description">ROI: {{ $tf('parameter.roi_desc') }}</p>
        <polygon-draw-tool only-rect :image="image" :points="points" :disabled="disabled" @change="(v) => (points = v)" />
      </div>
      <div v-else>
        {{ $tt('cant_load_image') }}
      </div>
    </template>
    <div v-else>{{ $tf('at_least_one_detector') }}</div>
  </div>
</template>

<script>
import { ObjectsTypeSingleForm } from '@/store/objects/get.module';
import PolygonDrawTool from '@/components/polygon-draw/index';
import { pointsArrayToXGeometry, xGeometryToPointsArray } from '@/apps/common/filters';

export default {
  name: 'stream-settings-roi-form',
  components: {
    PolygonDrawTool
  },
  props: {
    item: Object,
    image: String,
    loadImage: Function,
    isCreateMode: Boolean,
    loading: Boolean,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      objects: '',
      screenshotDataUrl: null,
      forms: []
    };
  },
  computed: {
    hasDetector() {
      return Object.values(this.item.stream_settings?.detectors || {}).some((v) => !!v);
    },
    points: {
      get: function () {
        const detectors = this.item.stream_settings.detectors;
        const detectorName = ObjectsTypeSingleForm[this.objects];
        const roi = detectors[detectorName]?.roi;
        let result = null;
        if (roi) {
          result = xGeometryToPointsArray(roi);
        }
        return result;
      },
      set: function (v) {
        const current = pointsArrayToXGeometry(v);
        if (this.item.stream_settings.detectors[ObjectsTypeSingleForm[this.objects]]) {
          this.item.stream_settings.detectors[ObjectsTypeSingleForm[this.objects]].roi = current;
        }
      }
    },
    availableObjects() {
      const detectors = this.item.stream_settings.detectors,
        result = this.$store.getters.enabledObjects
          .map((v) => {
            return detectors[ObjectsTypeSingleForm[v]] ? v : null;
          })
          .filter((v) => !!v);
      return result;
    },
    tabs() {
      return this.availableObjects.map((i) => ({ name: i, i18n: i }));
    }
  },
  watch: {
    availableObjects(v, p) {
      this.syncObjects();
    }
  },
  mounted() {
    this.syncObjects();
    this.loadImage && this.loadImage();
  },
  methods: {
    syncObjects() {
      this.objects = this.availableObjects[0] || '';
    },

    getForm(value) {
      this.$emit('getForm', value);
    }
  }
};
</script>
