<template>
  <el-form
    v-loading="loading"
    :size="$vars.sizes.form"
    :label-position="$vars.forms.label_position"
    :label-width="$vars.forms.label_width"
    :name="$options.name"
    :rules="rules"
    :model="item && item.stream_settings"
    :disabled="disabled"
    ref="form"
    class="camera-advanced-form"
  >
    <label class="camera-advanced-form-header">{{ $tf('detectors') }}</label>

    <div class="camera-advanced-form__block">
      <el-form-item :key="'detector-' + objects" v-for="objects in enabledObjects" :label="$tf(objects)">
        <el-checkbox name="detectors" :value="hasObjectsDetector(objects)" @change="(v) => toggleAvailableObjects(objects, v)" size="small" />
        <span class="parameter-default">{{ 'true' }}</span>
      </el-form-item>
    </div>

    <div class="camera-advanced-form__block">
      <label class="camera-advanced-form-header">{{ $tf('stream_rotation') }}</label>
      <el-tooltip effect="dark" :content="$tf('transformation_tooltip')" placement="right" popper-class="transformation-tooltip" :open-delay="800">
        <el-form-item prop="transformation" class="camera-advanced-form-transformation">
          <camera-transform-option class="camera-advanced-form-transformation__current" :option="video_transform" />
          <el-select name="transformation" v-model="video_transform">
            <el-option v-for="type in transformationTypes" :key="type.label" label=" " :value="type.label">
              <camera-transform-option :option="type.label" />
            </el-option>
          </el-select>
        </el-form-item>
      </el-tooltip>
    </div>

    <div class="camera-advanced-form__block">
      <label class="camera-advanced-form-header">{{ $tf('posting_objects') }}</label>
      <el-form-item :label="$tf('parameter.router_timeout_ms')" prop="router_timeout_ms">
        <el-input name="router_timeout_ms" v-model="item.stream_settings.router_timeout_ms" class="input--short-number"> </el-input>
        <span class="parameter-default">{{ defaultParameters.stream_settings.router_timeout_ms }}</span>
        <div class="parameter-description">{{ $tf('parameter.router_timeout_ms_desc') }}</div>
      </el-form-item>
    </div>

    <div class="camera-advanced-form__block" v-if="cameraMode">
      <label class="camera-advanced-form-header">{{ $tf('timestamp') }}</label>
      <el-form-item :label="$tf('parameter.use_stream_timestamp__simple')" prop="use_stream_timestamp">
        <el-checkbox name="use_stream_timestamp" v-model="item.stream_settings.use_stream_timestamp" size="small"> </el-checkbox>
        <span class="parameter-default">{{ defaultParameters.stream_settings.use_stream_timestamp }}</span>
        <div class="parameter-description">{{ $tf('parameter.use_stream_timestamp_desc') }}</div>
      </el-form-item>

      <el-form-item :label="$tf('parameter.start_stream_timestamp')" prop="start_stream_timestamp">
        <el-input name="start_stream_timestamp" v-model="item.stream_settings.start_stream_timestamp" class="input--short-number"> </el-input>
        <span class="parameter-default">{{ defaultParameters.stream_settings.start_stream_timestamp }}</span>
        <div class="parameter-description">{{ $tf('parameter.start_stream_timestamp_desc') }}</div>
      </el-form-item>
    </div>

    <div class="camera-advanced-form__block">
      <label class="camera-advanced-form-header">{{ $tf('other') }}</label>
      <el-form-item :label="$tf('parameter.ffmpeg_format')" prop="ffmpeg_format">
        <el-input name="ffmpeg_format" v-model="item.stream_settings.ffmpeg_format" placeholder="" size="small" class="input--short-number"> </el-input>
        <div class="parameter-description">{{ $tf('parameter.ffmpeg_format_desc') }}</div>
      </el-form-item>
    </div>

    <el-form-item :label="$tf('parameter.ffmpeg_params')" prop="ffmpeg_params">
      <el-input name="ffmpeg_params" v-model="ffmpeg_params" placeholder="" size="small" class="input--short-number"> </el-input>
      <div class="parameter-description">{{ $tf('parameter.ffmpeg_params_desc') }}</div>
    </el-form-item>

    <el-form-item v-if="showPlaySpeedParameter" :label="$tf('parameter.play_speed')" prop="play_speed">
      <el-input name="play_speed" v-model="item.stream_settings.play_speed" placeholder="" size="small" class="input--short-number"> </el-input>
      <span class="parameter-default">{{ defaultParameters.stream_settings.play_speed }}</span>
      <div class="parameter-description">{{ $tf('parameter.play_speed_desc') }}</div>
    </el-form-item>

    <el-form-item :label="$tf('parameter.imotion_threshold')" prop="imotion_threshold">
      <el-input name="imotion_threshold" v-model="item.stream_settings.imotion_threshold" size="small" class="input--short-number"> </el-input>
      <span class="parameter-default">{{ defaultParameters.stream_settings.imotion_threshold }}</span>
      <div class="parameter-description">{{ $tf('parameter.imotion_threshold_desc') }}</div>
    </el-form-item>

    <el-form-item :label="$tf('parameter.router_verify_ssl')" prop="router_verify_ssl">
      <el-checkbox name="router_verify_ssl" v-model="item.stream_settings.router_verify_ssl" size="small">
      </el-checkbox>
      <span class="parameter-default">{{ defaultParameters.stream_settings.router_verify_ssl }}</span>
      <div class="parameter-description">{{ $tf('parameter.router_verify_ssl_desc') }}</div>
    </el-form-item>

    <el-form-item v-if="hasDebugParameters">
      <el-button @click="toggleDebugParameters">{{ $tf('debug_parameters') }}</el-button>
    </el-form-item>

    <template v-if="showDebugParameters">
      <el-form-item v-if="item.stream_settings" :label="$tf('parameter.play_speed')" prop="play_speed">
        <el-input name="play_speed" v-model="item.stream_settings.play_speed" placeholder="" size="small" class="input--short-number"> </el-input>
        <span class="parameter-default">{{ defaultParameters.stream_settings.play_speed }}</span>
        <div class="parameter-description">{{ $tf('parameter.play_speed_desc') }}</div>
      </el-form-item>

      <el-form-item :label="$tf('parameter.disable_drops')" prop="disable_drops">
        <el-checkbox name="disable_drops" v-model="item.stream_settings.disable_drops" size="small" />
        <span class="parameter-default">{{ defaultParameters.stream_settings.disable_drops }}</span>
        <div class="parameter-description">{{ $tf('parameter.disable_drops_desc') }}</div>
      </el-form-item>

      <el-form-item :label="$tf('parameter.single_pass')" prop="single_pass">
        <el-checkbox name="single_pass" v-model="item.single_pass" size="small" />
        <span class="parameter-default">false</span>
        <div class="parameter-description">{{ $tf('parameter.single_pass_desc') }}</div>
      </el-form-item>
    </template>
  </el-form>
</template>

<script>
import { getNumberCorrector, getNumberValidator } from '@/apps/common/validator';
import CameraTransformOption from './camera-transform-option';
import _ from '@/apps/common/lodash';
import { ObjectsTypeSingleForm } from '@/store/objects/get.module';

const transformationTypes = [
  { value: 'None', label: 'none' },
  { value: 'FlipHorizontal', label: 'flip_horizontally' },
  { value: 'Rotate180', label: 'rotate_180' },
  { value: 'FlipVertical', label: 'flip_vertically' },
  { value: 'Transpose', label: 'transpose' },
  { value: 'Rotate90', label: 'rotate_90' },
  { value: 'Transverse', label: 'transverse' },
  { value: 'Rotate270', label: 'rotate_270' }
];

const baseRules = {
  azimuth: [
    {
      validator: getNumberValidator({ required: false, min: 0, max: 359.9, float: true }),
      tmessage: 'error.field.ufloat',
      trigger: 'change'
    }
  ],
  router_timeout_ms: [
    {
      trigger: 'change',
      tmessage: 'error.field.uint_max_min',
      validator: getNumberValidator({ required: false, min: 0, max: 300000 })
    }
  ],
  start_stream_timestamp: [
    {
      trigger: 'change',
      tmessage: 'error.field.uint_max_min',
      validator: getNumberValidator({ required: false, min: 0, max: 1e15 })
    }
  ],
  play_speed: [
    {
      trigger: 'change',
      tmessage: 'error.field.uint_max_min',
      validator: getNumberValidator({ required: true, type: 'number', float: true, min: -1, max: 100 })
    }
  ],
  imotion_threshold: [
    {
      trigger: 'change',
      tmessage: 'error.field.uint_max_min',
      validator: getNumberValidator({ required: true, float: true, min: 0, max: 1 })
    }
  ]
};

export default {
  name: 'camera-advanced-form',
  props: {
    state: Object,
    item: Object,
    defaultParameters: Object,
    loading: Boolean,
    multiEdit: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'camera'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: { CameraTransformOption },
  data: function () {
    return {
      rules: this.$applyRuleMessages(baseRules),
      transformationTypes,
      showDebugParameters: false
    };
  },
  mounted() {
    this.$emit('getForm', this.$refs.form);
  },
  computed: {
    enabledObjects() {
      return this.$store.getters.enabledObjects;
    },
    cameraMode() {
      return this.mode === 'camera';
    },
    ffmpeg_params: {
      get() {
        return (this.item.stream_settings?.ffmpeg_params || []).join(' ');
      },
      set(v) {
        this.item.stream_settings.ffmpeg_params = v.split(' ').filter((v) => !!v);
      }
    },
    video_transform: {
      get() {
        const v = this.item.stream_settings.video_transform;
        const type = getTransformationTypeByField('value', v);
        return getTransformationFieldValue('label', type);
      },
      set(v) {
        const type = getTransformationTypeByField('label', v);
        this.item.stream_settings.video_transform = getTransformationFieldValue('value', type);
      }
    },
    hasDebugParameters() {
      return this.mode === 'camera';
    },
    showPlaySpeedParameter() {
      return this.item.stream_settings && !this.hasDebugParameters;
    }
  },
  watch: {
    'item.stream_settings.filter_min_quality': getNumberCorrector({
      path: 'item.stream_settings.filter_min_quality',
      float: true
    }),
    'item.stream_settings.router_timeout_ms': getNumberCorrector({ path: 'item.stream_settings.router_timeout_ms' }),
    'item.stream_settings.start_stream_timestamp': getNumberCorrector({
      path: 'item.stream_settings.start_stream_timestamp'
    }),
    'item.stream_settings.play_speed': getNumberCorrector({ path: 'item.stream_settings.play_speed', float: true }),
    'item.stream_settings.imotion_threshold': getNumberCorrector({
      path: 'item.stream_settings.imotion_threshold',
      float: true
    }),
    availableObjects: function (v) {
      this.$emit('changeAvailableObjects', v);
    }
  },
  methods: {
    hasObjectsDetector(objects) {
      const settingsKey = ObjectsTypeSingleForm[objects];
      return !!this.item.stream_settings.detectors[settingsKey];
    },
    toggleAvailableObjects(objects, v) {
      const settingsKey = ObjectsTypeSingleForm[objects];
      const defaultSettings = this.defaultParameters.stream_settings.detectors[settingsKey];
      const currentSettings = this.item.stream_settings.detectors[settingsKey] || _.cloneDeep(defaultSettings);
      const enabled = v;
      this.$set(this.item.stream_settings.detectors, settingsKey, enabled ? currentSettings : null);
    },
    toggleDebugParameters() {
      this.showDebugParameters = !this.showDebugParameters;
    }
  }
};

function getTransformationTypeByField(fieldName, value) {
  return transformationTypes.find((type) => type[fieldName] === value);
}
function getTransformationFieldValue(fieldName, type) {
  return type?.[fieldName] || transformationTypes[0][fieldName];
}
</script>

<style lang="stylus">
.camera-advanced-form {
  &-header {
    display: inline-block;
    margin-bottom: 1rem;
  }

  &-transformation {
    width: 7rem;

    &__current {
      top: 0;
      left: 0;
      right: 0;
      position: absolute;
    }
  }

  &__block {
    margin-bottom: 36px;
  }
}

.transformation-tooltip{
  width: 46rem !important;
}
</style>
